@use "sass:math";
@use '~@THC/styles/_breakpoints';

@use '~@THC/styles/_color';

@use '~@THC/styles/_core';

@use '~@THC/styles/_typography';

$closeButtonHeight: core.$space7;
$gridGap: core.$space3;

.MFAWrapper {
	display: flex;
	text-align: center;

	&.fullScreen {
		background-color: color.$primary-black;
		inset: 0;
		position: fixed;
		z-index: 9999;

		.paymentsOTPContent {
			margin-top: 0;
		}

		.container {
			display: grid;
			gap: $gridGap;
			grid: $closeButtonHeight 1fr / 1fr;
			grid-auto-flow: row;
			grid-template-areas: 'toolbar' 'content';
			max-width: 100%;
			overflow-y: auto;
			padding-bottom: core.$space4;
			padding-top: core.$space4;

			@media (min-width: breakpoints.$breakpointMobile) {
				padding-bottom: core.$space6;
				padding-top: core.$space6;
			}

			.content {
				@media (min-width: breakpoints.$breakpointTabletMobile) {
					justify-content: center;
					margin-top: -($closeButtonHeight + $gridGap);
				}
			}
		}
	}

	&:not(.fullScreen) {
		.container {
			align-items: center;
			display: flex;
			flex-direction: column;
			padding-bottom: core.$space6;
			padding-top: core.$space7;

			@media (min-width: breakpoints.$breakpointMobile) {
				padding-bottom: core.$space2 * 7;
				padding-top: core.$space2 * 10;
			}

			@media (min-width: breakpoints.$breakpointTabletMobile) {
				padding-bottom: core.$space2 * 20;
				padding-top: core.$space2 * 12;
			}
		}
	}

	.toolbar {
		display: flex;
		grid-area: toolbar;
		justify-content: flex-end;
	}

	.content {
		align-items: center;
		display: flex;
		flex-direction: column;
		grid-area: content;

		> div {
			max-width: calc(#{core.$space} * 64);
		}
	}

	.title {
		@include typography.heading2();

		@media (max-width: breakpoints.$breakpointMobile) {
			// thc-fix - https://motorway.atlassian.net/browse/HC-56
			font-size: typography.$font-size * 1.5;
			line-height: typography.$font-size * 2;
		}
	}
}
