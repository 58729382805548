@use "sass:math";
@use '@THC/styles/_color.scss' as color;

@use '@THC/styles/core' as core;

@use '@THC/styles/modifiers' as modifiers;

@use '~Utilities/styles/siteNavigation' as siteNavigation;

$link-x-padding: core.$space1 * 3;
$subItem-x-padding: core.$space1 * 3;

.item:is(a, div) {
	align-items: center;
	color: inherit;
	display: flex;
	gap: core.$space3;
	height: core.$space8;
}

a.link {
	border: 0;
	padding-left: $link-x-padding;
	padding-right: $link-x-padding;
	position: relative;

	&:not(:disabled),
	&:not([aria-disabled='true']) {
		&:focus-visible {
			--thc-focus-ring-inset: 0;

			@include modifiers.focusRing();
		}

		span {
			@include siteNavigation.underline(300ms);

			--underline-color: #{color.$dark-grey-2};
		}

		&:hover > span {
			--underline-trigger: 1;
		}

		&[aria-current='true'] > span {
			--underline-trigger: 1;
			--underline-color: #{color.$primary-yellow};
		}
	}
}

.separatorContainer {
	padding: core.$space2 0;
}

.badgeWrapper {
	margin-left: auto;
}
