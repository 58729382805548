@use "sass:math";
@use '@THC/styles/core';

@use '~Utilities/styles/siteNavigation' as siteNavigation;

.niItem {
	padding: siteNavigation.$menuContentSpacing;
	position: relative;
	text-align: left;
}
