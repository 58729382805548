@use "sass:math";
@use '~@THC/styles/_core';

@use '~@THC/styles/_color';

@use '~@THC/styles/_typography';

@use '~@THC/styles/_breakpoints';

.paymentsOTPContent {
	display: flex;
	flex-direction: column;
	gap: core.$space7;
	justify-content: space-between;

	@media (max-width: breakpoints.$breakpointMobile - 1px) {
		margin-top: core.$space6;
	}

	> div:first-child {
		display: grid;
		grid-template-areas:
			'title'
			'.'
			'desc'
			'.'
			'authenticateBox';
		grid-template-columns: 1fr;
		// stylelint-disable-next-line
		grid-template-rows: auto core.$space5 auto core.$space3 auto;

		.title {
			display: block;
			grid-area: title;
			text-align: center;
		}

		.desc {
			display: flex;
			flex-direction: column;
			gap: core.$space3;
			grid-area: desc;
			text-align: center;

			> p {
				padding: 0;
			}
		}

		.authenticateBox {
			grid-area: authenticateBox;

			> label > div {
				margin: 0 auto;
			}
		}
	}

	.buttonWrapper {
		grid-area: button;
	}

	.subDesc {
		--thc-icon-accent-fill: #{color.$primary-black};

		margin-bottom: core.$space2;
		min-height: core.$space4;
		text-align: center;

		&.success {
			--thc-font-color: var(--thc-semantic-success);
		}

		&.error {
			--thc-font-color: var(--thc-semantic-error);
		}
	}
}

.title {
	@include typography.heading3();
}
