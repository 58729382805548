@use "sass:math";
@use '@THC/styles/color';

@use '~@THC/styles/_core';

@use '~@THC/styles/_typography';

@use '@THC/styles/modifiers';

@use '~Utilities/styles/siteNavigation' as siteNavigation;

$menuButton-x-padding: core.$space1 * 3;

.menuButton {
	@include typography.regular;

	align-items: center;
	appearance: none;
	background: none;
	border: none;
	color: color.$primary-white;
	cursor: pointer;
	display: flex;
	font-family: inherit;
	height: core.$space * 7;
	margin: 0;
	outline: none;
	padding: 0 $menuButton-x-padding;
	position: relative;
	width: 100%;

	&.menuButtonLink {
		border: none;
	}

	&[aria-current='true'] span {
		--underline-trigger: 1;
		--underline-color: #{color.$primary-yellow};
	}

	svg {
		margin-right: core.$space3;
	}

	span {
		@include siteNavigation.underline(200ms);

		--underline-spacing: 2;
		--underline-color: #{color.$dark-grey-2};
	}

	&:hover {
		& > span {
			--underline-trigger: 1;
		}
	}

	&:focus-visible {
		--thc-focus-ring-inset: 0;

		@include modifiers.focusRing();
	}

	.extensionContentWrapper {
		position: absolute;
		right: core.$space1 * 3;
	}
}
