@use "sass:math";
@use '~@THC/styles/breakpoints';
@use '~@THC/styles/core';

.content {
    margin: 0 auto;
    max-width: breakpoints.$breakpointContent;
    padding: core.$space4;
    width: 100%;

    @include breakpoints.minTabletMobile {
        padding: core.$space4 core.$space6;
    }
    
    @include breakpoints.minTablet {
        padding: core.$space6;
    }

    &.center {
        text-align: center;
    }
}