@use "sass:math";
@use '~@THC/styles/_core' as core;

@import '/utilities/styles/common.scss';

.signUpButtonContainer {
	margin-left: core.$space4;

	& > [data-thc-button] {
		padding-left: core.$space7;
		padding-right: core.$space7;
	}
}

ul.menuList {
	& > li {
		margin: 0 core.$space3;
		position: relative;

		&.actionButton {
			margin: 0 core.$space2;
		}
	}
}
