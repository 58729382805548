@use "sass:math";
/* stylelint-disable no-invalid-position-at-import-rule */

@use '@THC/styles/color';

@use '~@THC/styles/_core';

@use '~@THC/styles/_typography';

@use '@THC/styles/modifiers';

@use '~Utilities/styles/siteNavigation' as siteNavigation;

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

.dropdownButton {
	@include typography.regular;

	@include centered();

	background-color: transparent;
	border: none;
	font-family: inherit;
	height: siteNavigation.$menuItemHeight;
	outline: none;
	padding: 0;
	position: relative;

	&:not(:disabled, [aria-disabled='true']) {
		&:focus-visible {
			@include modifiers.focusRing();
		}
	}

	&[data-state='open'] > .chevron {
		transform: rotate(180deg);
	}

	span {
		@include siteNavigation.underline(300ms);

		--underline-color: #{color.$dark-grey-2};
	}

	&[data-state='open'] > span {
		--underline-trigger: 1;
	}

	&.isActive > span {
		--underline-trigger: 1;
		--underline-color: #{color.$primary-yellow};
	}

	&.primary {
		--thc-icon-fill: #{color.$primary-black};

		color: color.$primary-black;
	}

	&.secondary {
		--thc-icon-fill: #{color.$primary-white};

		color: color.$primary-white;
	}

	.chevron {
		@include centered();

		margin-left: core.$space2;
		transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
	}
}
