@use "sass:math";
@use '@THC/styles/core';

@use '~@THC/styles/_typography';

@use '@THC/styles/color';

@use '@THC/styles/modifiers';

@use '~Utilities/styles/siteNavigation' as siteNavigation;

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

.link {
	--thc-feedback-layer-border-radius: #{core.$space-8};
	@include typography.regular;

	align-items: center;
	background: none;
	border: 0;
	border-radius: core.$space-8;
	cursor: pointer;
	display: flex;
	font-family: inherit;
	max-height: siteNavigation.$menuItemHeight;
	padding: core.$space2;
	position: relative;
	text-align: left;
	text-decoration: none;
	user-select: none;

	&.iconRight {
		flex-direction: row-reverse;
		justify-content: space-between;
	}

	span {
		@include siteNavigation.underline(300ms);

		--underline-color: #{color.$dark-grey-2};
	}

	&:hover > span {
		--underline-trigger: 1;
	}

	&.isActive:not(.isListItem) > span {
		--underline-trigger: 1;
		--underline-color: #{color.$primary-yellow};
	}

	&.primary {
		color: color.$primary-black;
	}

	&.secondary {
		color: color.$primary-white;
	}

	&:not(:disabled, [aria-disabled='true']) {
		&::before {
			@include modifiers.feedbackLayer();
		}

		&:focus-visible {
			outline: none;

			@include modifiers.focusRing();
		}
	}

	.extensionPosition {
		display: flex;
		flex-grow: 1;

		&.extensionPositionLeft {
			justify-content: flex-start;
		}

		&.extensionPositionRight {
			justify-content: flex-end;
		}
	}

	.extensionContentWrapper {
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;
		right: core.$space2;
	}
}

.listItem {
	max-height: fit-content;
	padding: siteNavigation.$menuContentSpacing;

	&.primary {
		&:hover {
			--thc-feedback-layer-background-color: var(--thc-color-darken10);
		}
	}

	&.secondary {
		&:hover {
			--thc-feedback-layer-background-color: var(--thc-color-lighten10);
		}
	}

	&.isActive {
		font-weight: 500;
	}

	> svg {
		margin-right: core.$space3;
	}
}
