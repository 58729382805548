@use "sass:math";
@use '@THC/styles/color';

@use '@THC/styles/core';

@use '@THC/styles/modifiers';

@use '~@THC/styles' as styles;

@use '~Utilities/styles/siteNavigation' as siteNavigation;

$trigger-x-padding: core.$space1 * 3;

.header {
	display: flex;

	.trigger {
		@include styles.regular();

		align-items: center;
		background-color: transparent;
		border: none;
		color: color.$primary-white;
		display: flex;
		flex: 1;
		font-family: inherit;
		justify-content: space-between;
		padding: 0 $trigger-x-padding;
		position: relative;

		&:hover {
			cursor: pointer;

			& > .chevron {
				color: color.$primary-white;
			}
		}

		&:not(:disabled),
		&:not([aria-disabled='true']) {
			&:focus-visible {
				--thc-focus-ring-inset: 0;

				@include modifiers.focusRing();
			}
		}

		span {
			@include siteNavigation.underline(300ms);

			--underline-color: #{color.$dark-grey-2};

			&[aria-current='location'] {
				--underline-trigger: 1;
				--underline-color: #{color.$primary-yellow};
			}
		}

		@media (hover: hover) {
			&:hover span {
				--underline-trigger: 1;
			}
		}

		&[data-state='open'] span {
			--underline-trigger: 1;
		}

		&[data-state='open'] > .chevron {
			color: color.$primary-white;
			transform: rotate(180deg) translateY(20%);
		}

		.chevron {
			color: color.$light-grey-1;
			transition:
				transform 300ms cubic-bezier(0.87, 0, 0.13, 1),
				color 200ms ease-in-out;
		}
	}
}
