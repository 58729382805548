@use "sass:math";
@use '~@THC/styles/_color';

.content {
	--max-height: 300px;

	color: color.$primary-white;

	&[data-state='open'] {
		animation: slide-down 500ms cubic-bezier(0.87, 0, 0.13, 1);
	}

	&[data-state='closed'] {
		animation: slide-up 500ms cubic-bezier(0.87, 0, 0.13, 1);
	}
}

@keyframes slide-down {
	from {
		max-height: 0;
		overflow: hidden;
	}

	to {
		max-height: var(--max-height);
		overflow: visible;
	}
}

@keyframes slide-up {
	from {
		max-height: var(--max-height);
		overflow: visible;
	}

	to {
		max-height: 0;
		overflow: hidden;
	}
}
