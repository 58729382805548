@use "sass:math";
@use '~@THC/styles/_core' as core;

@use '~@THC/styles/_color' as color;

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

.menuList {
	@include centered();

	color: color.$primary-white;

	> li {
		margin-left: core.$space7;
		position: relative;
	}
}
