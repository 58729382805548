@use "sass:math";
@use '~@THC/styles/_color' as color;

@use '~@THC/styles/_core' as core;

@use '@THC/styles/modifiers' as modifiers;

@use '~@THC/styles/_typography' as typography;

@use '~Utilities/styles/siteNavigation' as siteNavigation;

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

$footer-link-x-padding: core.$space1 * 3;
$header-container-x-padding: core.$space1 * 3;

.container {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.navContainer {
	padding: core.$space2 0;
}

.headerContainer {
	margin-top: 0;
	padding-left: $header-container-x-padding;
	padding-right: $header-container-x-padding;

	h4 {
		@include typography.heading4;

		color: color.$primary-white;
	}

	p {
		@include typography.large;

		color: color.$light-grey-1;
		padding: 0;
	}
}

.footer {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	a.link {
		border-bottom: none;
		color: color.$light-grey-1;
		padding: core.$space3 $footer-link-x-padding;
		position: relative;
		transition: color 0.2s ease;
		width: 100%;

		span {
			@include siteNavigation.underline(200ms);

			--underline-spacing: 2;
			--underline-color: #{color.$dark-grey-2};
		}

		&:hover {
			color: color.$primary-white;

			& > span {
				--underline-trigger: 1;
			}
		}

		&:focus-visible {
			@include modifiers.focusRing();
		}

		.suffix {
			float: right;
		}
	}
}
