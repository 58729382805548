@use "sass:math";
@use '~@THC/styles/_core' as core;

/*
*	Nasty selector but required to override the styles applied to
*	list items in the defaultNavStyles mixin.
*/
body ul li.menuItem {
	margin-left: core.$space2;
	margin-right: 0;
}
