@use "sass:math";
@use '~@THC/styles/_core' as core;

@use '~@THC/styles/_color' as color;

@use '~@THC/styles/_typography' as typography;

@use '~Utilities/styles/siteNavigation' as siteNavigation;

@use '@THC/styles/modifiers' as modifiers;

$header-container-x-padding: core.$space1 * 3;

.navContainer {
	padding: core.$space2 0;
}

.headerContainer {
	margin-top: 0;
	padding-left: $header-container-x-padding;
	padding-right: $header-container-x-padding;

	&.accountSettingsLink {
		border: none;
	}

	h4 {
		@include typography.heading4;

		color: color.$primary-white;
	}

	p {
		@include typography.large;

		color: color.$light-grey-1;
		padding: 0;
	}
}

.accountSettingsLink {
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	position: relative;

	&:focus-visible {
		@include modifiers.focusRing();
	}

	span {
		@include siteNavigation.underline(300ms);

		--underline-color: #{color.$dark-grey-2};

		&[aria-current='location'] {
			--underline-trigger: 1;
			--underline-color: #{color.$primary-yellow};
		}
	}

	.accountSettingsLinkName {
		&:hover {
			& > span {
				--underline-trigger: 1;
			}
		}
	}
}
