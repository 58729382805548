@use "sass:math";
@use '@THC/styles/_typography' as typography;

@use '@THC/styles/core' as core;

@use '@THC/styles/modifiers' as modifiers;

$link-x-padding: core.$space1 * 3;
$subItem-x-padding: core.$space1 * 3;

a.subItem {
	@include typography.regular();

	align-items: center;
	border: 0;
	color: inherit;
	display: flex;
	height: core.$space8;
	min-width: 100%;
	padding-left: $subItem-x-padding;
	padding-right: $subItem-x-padding;
	position: relative;

	&:focus-visible {
		--thc-focus-ring-inset: 0;

		@include modifiers.focusRing();
	}

	&.isActive {
		font-weight: 500;
	}

	.extensionPosition {
		display: flex;
		flex-grow: 1;

		&.extensionPositionLeft {
			justify-content: flex-start;
		}

		&.extensionPositionRight {
			justify-content: flex-end;
		}
	}
}
