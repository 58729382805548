@use '@THC/styles/core';

@use '~@THC/styles/_color';

@mixin underline($transitionDuration: 100ms) {
	--underline-color: #{color.$primary-white};
	--underline-height: 1px;
	--underline-trigger: 0; // 0 = no underline, 1 = underline fully visible
	--underline-spacing: 0;

	position: relative;

	&::after {
		background-color: var(--underline-color);
		bottom: calc(-1px * var(--underline-spacing));
		content: '';
		height: var(--underline-height);
		left: 0;
		position: absolute;
		transform: scaleX(var(--underline-trigger));
		transform-origin: bottom left;
		transition: transform $transitionDuration;
		width: 100%;
		z-index: -1;
	}
}

$menuItemHeight: core.$space6;
$menuContentSpacing: 12px;
