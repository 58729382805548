@use "sass:math";
@use '~@THC/styles/color';

@use '@THC/styles/modifiers';

@use '~@THC/styles/_typography';

@use '~@THC/styles/_core';

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

.roundButton {
	--thc-focus-ring-border-radius: 50%;
	--thc-feedback-layer-border-radius: 50%;

	@include centered;

	@include typography.heading7();

	background: none;
	background-color: var(--button-background-color);
	border: none;
	border-radius: 50%;
	color: var(--button-color);
	cursor: pointer;
	height: core.$space6;
	opacity: var(--button-opacity);
	padding: 0;
	position: relative;
	width: core.$space6;

	&:focus-visible {
		@include modifiers.focusRing();
	}

	&:disabled,
	&[aria-disabled='true'] {
		--button-opacity: 0.4;

		pointer-events: none;
	}

	&::before {
		@include modifiers.feedbackLayer();
	}

	&.primary {
		--button-background-color: #{color.$primary-white};
		--button-color: #{color.$primary-black};

		> svg {
			--thc-icon-fill: #{color.$primary-black};
		}

		&:hover,
		&:active {
			--thc-feedback-layer-background-color: var(--thc-color-darken10);
		}
	}

	&.secondary {
		--button-background-color: #{color.$dark-grey-1};
		--button-color: #{color.$primary-white};

		> svg {
			--thc-icon-fill: #{color.$primary-white};
		}

		&:hover,
		&:active {
			--thc-feedback-layer-background-color: var(--thc-color-lighten10);
		}
	}

	&.brand {
		--button-background-color: #{color.$primary-yellow};
		--button-color: #{color.$primary-black};

		> svg {
			--thc-icon-fill: #{color.$primary-black};
		}

		&:hover,
		&:active {
			--thc-feedback-layer-background-color: var(--thc-color-lighten30);
		}
	}

	&.transparent {
		--button-background-color: none;
		--button-color: #{color.$light-grey-1};

		> svg {
			--thc-icon-fill: #{color.$light-grey-1};
		}

		&:hover,
		&:active {
			--thc-feedback-layer-background-color: var(--thc-color-lighten10);
		}
	}
}
