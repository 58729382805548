@use "sass:math";
@use '@THC/styles/core';

.container {
	display: flex;
	flex-direction: column;
	height: 100%;

	.signUpButtonContainer {
		margin-top: core.$space4;
	}

	.newGlobalExperienceUserButtonsContainer {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		gap: core.$space3;
		justify-content: flex-end;
	}
}
