@use "sass:math";
@use '@THC/styles/color';

@use '~Utilities/styles/zindex' as zIndex;

@use '@THC/styles/core';

$drawer-shadow-color: rgb(0 0 0 / 30%);
$drawer-transition-duration: 225ms;
$drawer-full-screen-height: 100dvh;
$drawer-full-screen-width: 100vw;
$drawer-paddings: core.$space4;
$drawer-border-radius: core.$border-radius * 2;
$drawer-content-x-padding: core.$space1 * 3;

.backdrop {
	background-color: rgb(0 0 0 / 50%);
	height: 100%;
	left: 0;
	opacity: 0;
	position: fixed;
	top: 0;
	transition: opacity $drawer-transition-duration cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	visibility: hidden;
	width: 100%;
	z-index: 15;
}

.open {
	opacity: 1;
	visibility: visible;
}

.drawer {
	box-shadow: 0 0 10px $drawer-shadow-color;
	display: flex;
	flex-direction: column;
	position: fixed;
	transform: translate3d(100%, 0, 0);
	transition: transform $drawer-transition-duration cubic-bezier(0, 0, 0.2, 1) 0ms;
	z-index: 15;

	&.yAxis {
		width: $drawer-full-screen-width;

		&.top {
			left: 0;
			top: 0;
			transform: translate3d(0, -100%, 0);

			&.roundedBorder {
				border-bottom: 1px solid color.$dark-grey-2;
				border-bottom-left-radius: $drawer-border-radius;
				border-bottom-right-radius: $drawer-border-radius;
			}
		}

		&.bottom {
			bottom: 0;
			left: 0;
			transform: translate3d(0, 100%, 0);

			&.roundedBorder {
				border-top: 1px solid color.$dark-grey-2;
				border-top-left-radius: $drawer-border-radius;
				border-top-right-radius: $drawer-border-radius;
			}
		}

		&.full {
			height: $drawer-full-screen-height;
		}

		&.large {
			height: min(core.$space * 100, 100%);
		}

		&.medium {
			height: min(core.$space * 75, 100%);
		}

		&.small {
			height: min(core.$space * 50, 100%);
		}

		&.fit-content {
			height: fit-content;
		}

		&.open {
			transform: translate3d(0, 0, 0);
		}
	}

	&.xAxis {
		height: $drawer-full-screen-height;

		&.left {
			left: 0;
			top: 0;
			transform: translate3d(-100%, 0, 0);

			&.roundedBorder {
				border-bottom-right-radius: $drawer-border-radius;
				border-right: 1px solid color.$dark-grey-2;
				border-top-right-radius: $drawer-border-radius;
			}
		}

		&.right {
			right: 0;
			top: 0;
			transform: translate3d(100%, 0, 0);

			&.roundedBorder {
				border-bottom-left-radius: $drawer-border-radius;
				border-left: 1px solid color.$dark-grey-2;
				border-top-left-radius: $drawer-border-radius;
			}
		}

		&.full {
			width: 100%;
		}

		&.large {
			width: min(core.$space * 100, 100%);
		}

		&.medium {
			width: min(core.$space * 75, 100%);
		}

		&.small {
			width: min(core.$space * 50, 100%);
		}

		&.fit-content {
			width: fit-content;
		}

		&.open {
			transform: translate3d(0, 0, 0);
		}
	}

	&.primary {
		background-color: color.$primary-white;
	}

	&.secondary {
		background-color: color.$primary-black;
	}
}

.topBar {
	display: flex;
	justify-content: flex-end;
	margin: 0;
	padding: $drawer-paddings;
	padding-bottom: core.$space2;
	width: 100%;
}

.content {
	height: auto;
	overflow-y: auto;
	padding: 0 $drawer-content-x-padding $drawer-paddings;

	&.contentFullHeight {
		height: 100%;
	}
}
