@use "sass:math";
@use '@THC/styles/core';

@use '@THC/styles/color';

@use '~Utilities/styles/siteNavigation' as siteNavigation;

.separator {
	background-color: color.$dark-grey-2;
	height: 1px;
	max-width: 100%;

	&.full {
		margin: core.$space3 0;
	}

	&.large {
		margin: siteNavigation.$menuContentSpacing;
	}

	&.small {
		margin: siteNavigation.$menuContentSpacing core.$space4;
	}

	&.short {
		margin-bottom: core.$space3;
		margin-top: core.$space3;
	}

	&.tall {
		margin-bottom: core.$space4;
		margin-top: core.$space4;
	}
}
