@use "sass:math";
@use '~@THC/styles/breakpoints';

@use '~@THC/styles/core';

@use '~@THC/styles/_color' as color;

@import '~@motorway/motorway-storybook-cra/src/common-styles/_index.scss';

@import '/utilities/styles/common.scss';

:global(.mw-parent-theme-dark) {
	.navLinksWrapper {
		background-color: var(--thc-color-primary-black);
		padding: core.$space-24;

		.navLinksSection {
			background-color: var(--thc-color-off-black);
			border-radius: core.$space-16;
			max-width: 100%;
		}

		[data-thc-logo] {
			display: flex;
			justify-content: center;
			margin-top: $marginGeneric * 7;

			svg {
				height: $marginGeneric * 7;
				width: $marginGeneric * 24;
			}
		}

		.socialLinks {
			display: flex;
			justify-content: center;
			margin-top: $marginGeneric * 6;

			li {
				@include dimensions($marginGeneric * 5);

				margin: 0 $marginGeneric;

				a {
					align-items: center;
					background-color: var(--thc-color-dealer-black);
					border: 0;
					border-radius: 100%;
					display: flex;
					height: 100%;
					justify-content: center;

					svg {
						color: color.$primary-white;
					}

					&:hover {
						background-color: var(--thc-color-primary-black);
					}
				}
			}

			:global(.motorway-svg-fill) {
				fill: $natural_black;
			}
		}

		.sellerSiteButtonRow {
			margin-top: $marginGeneric * 5;

			:global(.mw-button) {
				margin: 0 auto;
				max-width: $marginGeneric * 31;
			}
		}

		.ancillaryPages {
			@include listDot($narrow: true, $dotAfter: true);

			display: flex;
			flex-wrap: wrap;
			font-weight: 500;
			justify-content: center;
			margin-bottom: 0;
			margin-top: $marginGeneric * 4;

			a {
				@include mw-link-nav-white();
			}
		}

		.itpMessage {
			margin-bottom: $marginGeneric * 6;
			margin-top: $marginGeneric * 4;
			padding-bottom: 0;
			max-width: 90ch;
			margin-inline: auto;
		}

		.copyright {
			margin-bottom: $marginGeneric * 7;
			padding-bottom: 0;
		}

		@include breakpoints.minTablet {
			padding: core.$space-48;

			[data-thc-logo] {
				margin-top: $marginGeneric * 4;

				svg {
					height: $marginGeneric * 6;
					width: $marginGeneric * 20;
				}
			}

			.socialLinks {
				margin-top: $marginGeneric * 4;
			}

			.sellerSiteButtonRow {
				margin-top: $marginGeneric * 5.25;
			}

			.ancillaryPages {
				margin-top: $marginGeneric * 4.75;
			}

			.copyright {
				margin-bottom: $marginGeneric * 5;
				padding-bottom: 0;
			}
		}
	}

	.fixedBottomGap {
		background-color: $natural_black;
		height: $marginGeneric * 19;
	}
}
